<script setup>
  import { shallowRef, defineProps } from 'vue'
  import { TresCanvas, useRenderLoop } from '@tresjs/core'

  defineProps({
    disturbed: Boolean,
    ringSpeedMulDefault: Number,
    color: String
    })
  //import MeshStandardMaterial from 'three'
  //const material = new MeshStandardMaterial({ color: 0x00ff00 wireframe=true})
  const ringRef = shallowRef(null)
  const miniRingRef = shallowRef(null)
  const cubeRef = shallowRef(null)

  /*const state = reactive({
    ringSpeed: {},
    });*/

  const { onLoop } = useRenderLoop()
  
    onLoop(({ delta, elapsed }) => {
        //const speedMul = this.props.disturbed ? this.props.speedMulDefault.value * 100 : this.props.speedMulDefault.value;
        const speedMul = 1;
        if (ringRef.value) {
            ringRef.value.rotation.x += (delta/10)*speedMul;
            ringRef.value.rotation.y += (delta/5)*speedMul;
            ringRef.value.rotation.z = (elapsed*0.1)*speedMul
        }
        if (miniRingRef.value) {
            miniRingRef.value.rotation.x += (delta/2)*speedMul
            miniRingRef.value.rotation.y += (delta/4)*speedMul
            miniRingRef.value.rotation.z = (elapsed*0.4)*speedMul
        }
        if (cubeRef.value) {
            cubeRef.value.rotation.x += (delta/0.34)*speedMul
            cubeRef.value.rotation.y += (delta/0.89)*speedMul
            cubeRef.value.rotation.z = (elapsed * 1.12)*speedMul
        }
    })
</script>

<template>
    <div id="ringHolo" border-top="20px">
        <TresCanvas
            clear-color="#1a1a1a"
        >        
            <TresPerspectiveCamera 
            :position="[2.3, 2.3, 2.3]"
            :look-at="[0, 0, 0]"
            />
            <TresMesh 
            ref="ringRef"
            :rotation="[-Math.PI / 1, 0, 0]">
            <TresTorusGeometry :args="[1.2, 0.25, 12, 25]" />
            <TresMeshStandardMaterial 
                color="cyan" 
                wireframe />
            </TresMesh>
            <TresMesh 
            ref="miniRingRef"
            :rotation="[-Math.PI / 1, 0, 0]">
            <TresTorusGeometry :args="[0.7, 0.12, 7, 14]" />
            <TresMeshStandardMaterial 
                color="cyan" 
                wireframe />
            </TresMesh>
            <TresMesh 
            ref="cubeRef"
            :rotation="[-Math.PI / 1, 0, 0]">
            <TresTorusGeometry :args="[0.19, 0.04, 4, 12]"/>
            <TresMeshStandardMaterial 
                color="cyan" 
                wireframe />
            </TresMesh>
            <TresAmbientLight :intensity="5" />
        </TresCanvas>
    </div>
  </template>

<script>
</script>

<style>
#ringHolo {
  height: 100%;
  width: 100%;
  background-color: #000;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 10px;
}
</style>