// main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

//import registerInterceptor from './logic/interceptor.js'

//import TypingText from './components/TypingText.vue'
//import Tres from '@tresjs/core'
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

//export const app = createApp(App).use(router).mount('#app')
const app = createApp(App)
//app.component('typing-text',TypingText)

app.use(router)
app.mount('#app')
//app.use(Tres)
//app.mount('#app')
//Vue.use(BootstrapVue)
//Vue.config.productionTip = false
//Vue.http.headers.common['Access-Control-Allow-Origin'] = true
/*
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

*/

// Set defaults
axios.defaults.baseURL = process.env.VUE_APP_API_ADDRESS // URL for API Server
//axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';