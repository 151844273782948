<!-- views/Dashboard.vue -->
<template>
  <RequireAuth>
    <div>
      <div>
        <button class="btn btn-primary" @click="sendEmail(1)">Stardew</button>
        <button class="btn btn-primary" @click="sendEmail(3)">Minecraft</button>
        <button class="btn btn-primary" @click="sendEmail(4)">Vintage</button>
      </div>
      <div>
        <button class="btn btn-primary" @click="sendEmail(5)">Stationeers</button>
        <button class="btn btn-primary" @click="sendEmail(6)">Nudge</button>
        <button class="btn btn-primary" @click="prepJam()">Spotify Jam</button>
      </div>
    </div>
    <transition name="slide">
      <div id="jambox" v-if="jamPrepActive">
        <input type="text" v-model="userInput" placeholder="Spotify Jam Link">
        <button class="btn btn-primary" @click="sendEmail(2, userInput)">SEND</button>
      </div>
    </transition>
    <transition name="slide">
      <div id="errormsg" class="error-message" v-show="errorMessage">
        {{ errorMessage }}
      </div>
    </transition>
  </RequireAuth>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
import RequireAuth from '../components/RequireAuth.vue'

export default {
  setup() {
    const errorMessage = ref('')
    const jamPrepActive = ref(false)
    //const userInput = ref('')

    async function sendEmail(id,userInput="") {
      errorMessage.value = ""
      id = id.toString() // TODO: SANITIZE!!
      userInput = userInput.toString()
      try {
        const token = localStorage.getItem('user-token') // replace this with how you store your jwt token
        const sanitizedUserInput = userInput.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/'/g, "&quot;"); // sanitize user input
        const response = await axios.post('/send-email', { id, userInput: sanitizedUserInput }, {
          headers: {
            Authorization: `${token}`
          }
        })
        console.log(response.data)
        if (response.status === 200) {
          errorMessage.value = "Alert sent successfully."
        }
      } catch (error) {
        errorMessage.value = "Request failed. \r\n"+error.message
        console.error(error)
      }
    }

    async function prepJam() {
      jamPrepActive.value = true;
    }

    return {
      errorMessage,
      sendEmail,
      prepJam,
      jamPrepActive
    }
  },
  data() {
    return {
      timerCount: 6
    }
  },
  components: {
    RequireAuth
  },
  watch: {
    timerCount: {
      handler(value) {

        if (value < 0) {
          setTimeout(() => {
            this.timerCount--;
          },1000)
        }
      }
    }
  },
  immediate: true // This ensures the watcher is triggered upon creation
}
</script>

<style scoped>
body {
  background-color: #000;
  color: #00f;
}
#control {
  margin: 10px;
  padding: 10px;
  width: 35%;
  border: 1px solid rgba(0, 140, 255, 0.452);
  border-radius: 5px;
  background-color: #2a2f35;
  color: rgb(0, 132, 255);
  margin-left: auto;
  margin-right: auto;
}
#button {
  margin: 10px;
  padding: 10px;
  width: 35%;
  color: rgb(0, 132, 255);
  margin-left: auto;
  margin-right: auto;
}

.slide-enter-active, .slide-leave-active {
    transition: width 0.5s;
    animation: typing 0.5s steps(40, end), blink-caret .35s step-end infinite;
  }
    .slide-enter, .slide-leave-to {
    width: 0;
  }

  .error-message {
    color: rgb(112, 206, 223);
    overflow: hidden;
    border-right: .65em solid rgb(152, 240, 255);
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    white-space: pre-wrap;
    margin-top: 10px;
  }
  
  #errormsg {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
    word-wrap: break-word;
  }
  
  #jambox {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    word-wrap: break-word;
    word-wrap: break-word;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(177, 255, 251); }
  }
</style>