<script setup>
  import RingHolo from './components/RingHologram.vue'
</script>

<template>
  <img alt="Wrenlink logo" src="./assets/logo.png" height="100px" width="auto">
  <p>Welcome to Wrenlink</p>
  <div id="app" border-top="20px">
    <div id="logo" >
      <RingHolo />
    </div>
    <div id="page">
      <router-view />
    </div>
  </div>
</template>

<script>

  export default {
    name: 'App'
  }
</script>

<style>
html,
body {
  background-color: #1a1a1a; /* Dark grey background */
  color: #00faff; /* Light blue text */
  font-family: 'Courier New', Courier, monospace;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#app {
  text-align: center;
  margin-top: 60px;
  height: 100%;
  width: 100%;
}
#page {
  text-align: center;
  margin-top: 60px;
  height: 10%;
  width: 100%;
}

/* Light blue borders for inputs and buttons */
input, button {
  border: 2px solid #00faff;
  background: none;
  color: #00faff;
}

/* Dark grey background with light blue hover for buttons */
button {
  background-color: #1a1a1a;
}

button:hover {
  background-color: #00faff;
  color: #1a1a1a;
}

#logo {
  height: 20%;
  width: 40%;
  background-color: #000;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 10px;
}

#page {
  height: 100%;
  width: 100%;
  border-top: 10px;
  margin-top: 10px;
}
</style>