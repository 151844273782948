<script setup>

</script>

<template>
    <div id="reqauth" v-show="!loading">
        <slot>
        </slot>
        <!-- Your component's content here -->
    </div>
</template>

<script>
    import axios from 'axios';
    import { ref } from 'vue'


    
    export default {
        setup() {
            const loading = ref(false)
            loading.value = false
            
            
            return { loading }
        },
        beforeUnmount() {
            axios.interceptors.response.eject(this.interceptor)
        },
        created() {
            // Add an interceptor to the axios instance
            axios.interceptors.response.use(
                response => response,
                error => {
                    if (error.response.status === 401) {
                        // Redirect to the login page
                        window.location.href = '/';
                        const loading = ref(false)
                        loading.value = true;
                    }
                    return Promise.reject(error);
                }
            );
        }
        
    };
</script>