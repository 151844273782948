<template>
    <div class="container py-5 text-center">
      <form @submit.prevent="login">
        
        <div id="control">
          <label for="username">Username:</label>
          <input type="text" id="username" v-model="username">
        </div>
        <div id="control">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password">
        </div>  
        <div id="button">
          <button type="submit" class="btn btn-primary" :disabled="loading">
            <span v-if="loading">Logging in...</span>
            <span v-else>Login</span>
          </button>
        </div>
        
        <transition name="slide">
            <div id="errormsg" class="error-message" v-show="errorMessage">
            {{ errorMessage }}
            </div>
        </transition>
        <typing-text :message="errorMessage" />
      </form>
    </div>
</template>
  
  <script>
  import axios from 'axios'
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  
  export default {
    setup() {
      const username = ref('wrenling')
      const password = ref('')
      const loading = ref(false)
      const errorMessage = ref('')
      const router = useRouter()
  
      const login = async () => {
        loading.value = true
        errorMessage.value = ""
        try {
          const response = await axios.post('/api/login', { username: username.value, password: password.value })
          
          if (response.status === 401) {
            throw new Error('Login failed')
          }
  
          localStorage.setItem('user-token', response.data.token)
          axios.defaults.headers.common['Authorization'] = `${response.data.token}`
          router.push('/dashboard')
        } catch (error) {
          errorMessage.value = "Login failed. \r\n"+error.message
          console.error('Login error:', error.message)
        } finally {
          loading.value = false
        }
      }
  
      return { username, password, login, loading, errorMessage }
    }
  }
  </script>
  
  <style scoped>
  body {
    background-color: #2a2f35;
    color: #00f;
  }
  
  .slide-enter-active, .slide-leave-active {
    transition: width 0.5s;
    animation: typing 0.5s steps(40, end), blink-caret .35s step-end infinite;
  }
    .slide-enter, .slide-leave-to {
    width: 0;
  }

  .error-message {
    color: rgb(112, 206, 223);
    overflow: hidden;
    border-right: .65em solid rgb(152, 240, 255);
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    white-space: pre-wrap;
  }
  
  #errormsg {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
    word-wrap: break-word;
    max-height: 70px;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: rgb(177, 255, 251); }
  }
  
  #control {
    margin: 10px;
    padding: 10px;
    width: 35%;
    border: 1px solid rgba(0, 140, 255, 0.452);
    border-radius: 5px;
    background-color: #2a2f35;
    color: rgb(0, 132, 255);
    margin-left: auto;
    margin-right: auto;
  }
  #button {
    margin: 10px;
    padding: 10px;
    width: 35%;
    color: rgb(0, 132, 255);
    margin-left: auto;
    margin-right: auto;
  }
  </style>